import React, { useEffect } from 'react'

const CollectionNameDropdown = props => {
  const { page, name, setName } = props

  useEffect(() => {
    setName('')
  }, [page])

  return (
    <div style={{ height: '30px', display: 'flex', alignItems: 'center' }}>
      <select
        value={name}
        onChange={e => {
          setName(e.target.value)
        }}
        style={{ paddingRight: '10px' }}
      >
        {page === 'home' ? (
          <>
            <option value="">배너를 선택하세요.</option>
            <option value="main">main</option>
            <option value="card">card</option>
            <option value="trio card">trio</option>
            <option value="artist">artist</option>
            <option value="md card">md</option>
            <option value="spotlight">spotlight</option>
            <option value="mini cards">mini</option>
            <option value="top table">top</option>
            <option value="free">free</option>
            <option value="center card">center</option>
          </>
        ) : page === 'new' ? (
          <>
            <option value="">배너를 선택하세요.</option>
            <option value="new main">new main</option>
          </>
        ) : page === 'search' ? (
          <>
            <option value="">배너를 선택하세요.</option>
            <option value="categories">categories</option>
          </>
        ) : (
          page === 'ai' && (
            <>
              <option value="">배너를 선택하세요.</option>
              <option value="ai card">card</option>
            </>
          )
        )}
      </select>
    </div>
  )
}

export default CollectionNameDropdown

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import LoadingSpinner from '../Common/Loading/LoadingSpinner'

const VersionBanner = props => {
  const {
    version,
    setVersion,
    getVersion,
    liveV,
    newV,
    prevV,
    allV,
    lang,
    setPopup,
    platform,
    isLoading,
  } = props
  const [newOpen, setNewOpen] = useState(false)
  const [newVersion, setNewVersion] = useState('')
  const [newDesc, setNewDesc] = useState('')
  const [edit, setEdit] = useState('')
  const [more, setMore] = useState('')
  const [newB, setNewB] = useState([])
  const [liveB, setLiveB] = useState([])
  const [prevB, setPrevB] = useState([])

  useEffect(() => {
    setEdit('')
  }, [version, lang])

  useEffect(() => {
    if (more === 'new') {
      setNewB([...newV])
    } else if (more === 'live') {
      setLiveB([...liveV])
    } else if (more === 'prev') {
      setPrevB([...prevV])
    } else {
      newV.length <= 10
        ? setNewB([...newV])
        : setNewB([...newV].slice(-10, newV.length))
      liveV.length <= 10
        ? setLiveB([...liveV])
        : setLiveB([...liveV].slice(-10, newV.length))
      prevV.length <= 10
        ? setPrevB([...prevV])
        : setPrevB([...prevV].slice(-10, newV.length))
    }
  }, [more, lang])

  useEffect(() => {
    newV.length <= 10
      ? setNewB([...newV])
      : setNewB([...newV].slice(-10, newV.length))
    liveV.length <= 10
      ? setLiveB([...liveV])
      : setLiveB([...liveV].slice(-10, newV.length))
    prevV.length <= 10
      ? setPrevB([...prevV])
      : setPrevB([...prevV].slice(-10, prevV.length))
  }, [allV, newV, liveV, prevV])

  const clickNew = () => {
    if (allV.filter(item => item.VERSION === newVersion).length > 0) {
      alert('이미 존재하는 버전입니다.')
    } else {
      const params = {
        version: newVersion,
        description: newDesc.replaceAll(/(?:\r\n|\r\n)/g, '<br>'),
        lang: lang,
        platform: platform ? platform : 'stipopapp',
      }

      axios
        .post(`/new/webAdmin/v2/collection/version`, params, {
          headers: {
            token: sessionStorage.getItem('token'),
          },
        })
        .then(({ data }) => {
          setNewOpen(false)
          getVersion(lang, 'copy')
          setVersion({ id: data.versionId, version: newVersion, status: 'W' })
          setNewDesc('')
        })
    }
  }

  const versionUpdate = (seq, version, desc, status) => {
    if (
      allV
        .filter(item => item.SEQ !== seq)
        .filter(item => item.VERSION === version).length > 0
    ) {
      alert('이미 존재하는 버전입니다.')
      return
    }
    const params = {
      seq,
      version,
      desc,
    }

    axios
      .put(`/new/webAdmin/v2/collection/version`, params, {
        headers: {
          token: sessionStorage.getItem('token'),
        },
      })
      .then(res => {
        console.log(res)
        setEdit('')
        setVersion({ id: seq, version: version, status: status })
      })
  }

  return (
    <Wrapper>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Version>
            <Title>새로운 큐레이션</Title>
            {newB.map((item, index) =>
              edit === item.SEQ ? (
                <div key={index}>
                  <VerNum active={version && version.id === item.SEQ}>
                    <div
                      id="version"
                      onClick={() => {
                        setVersion({
                          id: item.SEQ,
                          version: item.VERSION,
                          status: item.STATUS,
                        })
                      }}
                    >
                      - {item.VERSION}
                    </div>
                    <div id="icon">🕒</div>
                  </VerNum>
                  <VersionInputWrapper edit={edit === item.SEQ}>
                    <div>
                      <div>버전</div>
                      <input
                        type={'text'}
                        value={item.VERSION}
                        onChange={e => {
                          setNewB(
                            newB.map(newb =>
                              newb.SEQ === edit
                                ? { ...newb, VERSION: e.target.value }
                                : newb
                            )
                          )
                        }}
                      />
                    </div>
                    <div>
                      <div>설명</div>
                      <textarea
                        value={item.DESCRIPTION}
                        onChange={e => {
                          setNewB(
                            newB.map(newb =>
                              newb.SEQ === edit
                                ? { ...newb, DESCRIPTION: e.target.value }
                                : newb
                            )
                          )
                        }}
                      />
                    </div>
                    <ButtonWrapper style={{ width: '100%', marginLeft: '0' }}>
                      <button
                        onClick={() => {
                          versionUpdate(
                            item.SEQ,
                            item.VERSION,
                            item.DESCRIPTION,
                            item.STATUS
                          )
                        }}
                        style={{ backgroundColor: '#007ffb' }}
                      >
                        Save
                      </button>
                    </ButtonWrapper>
                  </VersionInputWrapper>
                </div>
              ) : (
                <VerWrapper key={index}>
                  <VerNum active={version && version.id === item.SEQ}>
                    <div
                      id="version"
                      onClick={() => {
                        setVersion({
                          id: item.SEQ,
                          version: item.VERSION,
                          status: item.STATUS,
                        })
                      }}
                    >
                      - {item.VERSION}
                    </div>
                    <div id="icon">🕒</div>
                  </VerNum>
                  {version && version.id === item.SEQ && (
                    <>
                      <VerDesc>
                        {item.DESCRIPTION.replaceAll('<br>', '\r\n')}
                      </VerDesc>
                      <ButtonWrapper>
                        <button
                          onClick={() => {
                            setPopup({ id: item.SEQ, type: 'delete' })
                          }}
                          style={{
                            backgroundColor: '#dc143c',
                            marginRight: '5px',
                          }}
                        >
                          Delete
                        </button>
                        <button
                          onClick={() => {
                            setPopup({ id: item.SEQ, type: 'copy' })
                          }}
                          style={{
                            backgroundColor: '#ff4500',
                            marginRight: '5px',
                          }}
                        >
                          Copy
                        </button>
                        <button
                          onClick={() => {
                            setEdit(item.SEQ)
                            setNewOpen(false)
                          }}
                          style={{
                            backgroundColor: '#007ffb',
                          }}
                        >
                          Edit
                        </button>
                      </ButtonWrapper>
                    </>
                  )}
                </VerWrapper>
              )
            )}
            {newOpen && (
              <VersionInputWrapper>
                <div>
                  <div>버전</div>
                  <input
                    type={'text'}
                    onChange={e => {
                      setNewVersion(e.target.value)
                    }}
                  />
                </div>
                <div>
                  <div>설명</div>
                  <textarea
                    onChange={e => {
                      setNewDesc(e.target.value)
                    }}
                  />
                </div>
              </VersionInputWrapper>
            )}
            {more !== 'new' && !newOpen && newV.length > 10 && (
              <More
                onClick={() => setMore('new')}
                style={{
                  width: '100%',
                  textAlign: 'end',
                  padding: '5px 10px 0 0',
                }}
              >
                전체 보기
              </More>
            )}
            <div
              style={{
                marginTop: '10px',
                width: '100%',
                display: 'flex',
                justifyContent: newOpen ? 'flex-end' : 'center',
              }}
            >
              {newOpen && (
                <button
                  onClick={() => {
                    setNewOpen(false)
                  }}
                  style={{
                    width: '100%',
                    height: '35px',
                    backgroundColor: '#dc143c',
                    color: '#fff',
                    borderRadius: '5px',
                    border: 'none',
                    padding: '0 10px',
                    boxSizing: 'border-box',
                    marginRight: newOpen && '5px',
                  }}
                >
                  닫기
                </button>
              )}
              <button
                onClick={() => {
                  if (!newOpen) {
                    setNewOpen(true)
                    setEdit('')
                  } else {
                    clickNew()
                  }
                }}
                style={{
                  width: '100%',
                  height: '35px',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  borderRadius: '5px',
                  border: 'none',
                  padding: '0 10px',
                  boxSizing: 'border-box',
                  fontWeight: 'bold',
                }}
              >
                버전 추가
              </button>
            </div>
          </Version>
          <Version>
            <Title>라이브 버전</Title>
            {liveB.map((item, index) =>
              edit === item.SEQ ? (
                <div key={index}>
                  <VerNum active={version && version.id === item.SEQ}>
                    <div
                      id="version"
                      onClick={() => {
                        setVersion({
                          id: item.SEQ,
                          version: item.VERSION,
                          status: item.STATUS,
                        })
                      }}
                    >
                      - {item.VERSION}
                    </div>
                    <div id="icon">✅</div>
                  </VerNum>
                  <VersionInputWrapper edit={edit === item.SEQ}>
                    <div>
                      <div>버전</div>
                      <input
                        type={'text'}
                        value={item.VERSION}
                        onChange={e => {
                          setLiveB(
                            liveB.map(liveb =>
                              liveb.SEQ === edit
                                ? { ...liveb, VERSION: e.target.value }
                                : liveb
                            )
                          )
                        }}
                      />
                    </div>
                    <div>
                      <div>설명</div>
                      <textarea
                        value={item.DESCRIPTION}
                        onChange={e => {
                          setLiveB(
                            liveB.map(liveb =>
                              liveb.SEQ === edit
                                ? { ...liveb, DESCRIPTION: e.target.value }
                                : liveb
                            )
                          )
                        }}
                      />
                    </div>
                    <ButtonWrapper style={{ width: '100%', marginLeft: '0' }}>
                      <button
                        onClick={() => {
                          versionUpdate(
                            item.SEQ,
                            item.VERSION,
                            item.DESCRIPTION
                          )
                        }}
                        style={{ backgroundColor: '#007ffb' }}
                      >
                        Save
                      </button>
                    </ButtonWrapper>
                  </VersionInputWrapper>
                </div>
              ) : (
                <VerWrapper key={index}>
                  <VerNum active={version && version.id === item.SEQ}>
                    <div
                      id="version"
                      onClick={() => {
                        setVersion({
                          id: item.SEQ,
                          version: item.VERSION,
                          status: item.STATUS,
                        })
                      }}
                    >
                      - {item.VERSION}
                    </div>
                    <div id="icon">✅</div>
                  </VerNum>
                  {version && version.id === item.SEQ && (
                    <>
                      <VerDesc>
                        {item.DESCRIPTION.replaceAll('<br>', '\r\n')}
                      </VerDesc>
                      <ButtonWrapper>
                        <button
                          onClick={() => {
                            setPopup({ id: item.SEQ, type: 'copy' })
                          }}
                          style={{
                            backgroundColor: '#ff4500',
                            marginRight: '5px',
                          }}
                        >
                          Copy
                        </button>
                        <button
                          onClick={() => {
                            setEdit(item.SEQ)
                            setNewOpen(false)
                          }}
                          style={{
                            backgroundColor: '#007ffb',
                          }}
                        >
                          Edit
                        </button>
                      </ButtonWrapper>
                    </>
                  )}
                </VerWrapper>
              )
            )}
            {more !== 'live' && liveV.length > 10 && (
              <More
                onClick={() => setMore('live')}
                style={{
                  width: '100%',
                  textAlign: 'end',
                  padding: '5px 10px 0 0',
                }}
              >
                전체 보기
              </More>
            )}
          </Version>
          <Version>
            <Title>이전 버전</Title>
            {prevB.map((item, index) =>
              edit === item.SEQ ? (
                <div key={index}>
                  <VerNum active={version && version.id === item.SEQ}>
                    <div
                      id="version"
                      onClick={() => {
                        setVersion({
                          id: item.SEQ,
                          version: item.VERSION,
                          status: item.STATUS,
                        })
                      }}
                    >
                      - {item.VERSION}
                    </div>
                  </VerNum>
                  <VersionInputWrapper edit={edit === item.SEQ}>
                    <div>
                      <div>버전</div>
                      <input
                        type={'text'}
                        value={item.VERSION}
                        onChange={e => {
                          setPrevB(
                            prevB.map(prevb =>
                              prevb.SEQ === edit
                                ? { ...prevb, VERSION: e.target.value }
                                : prevb
                            )
                          )
                        }}
                      />
                    </div>
                    <div>
                      <div>설명</div>
                      <textarea
                        value={item.DESCRIPTION}
                        onChange={e => {
                          setPrevB(
                            prevB.map(prevb =>
                              prevb.SEQ === edit
                                ? { ...prevb, DESCRIPTION: e.target.value }
                                : prevb
                            )
                          )
                        }}
                      />
                    </div>
                    <ButtonWrapper style={{ width: '100%', marginLeft: '0' }}>
                      <button
                        onClick={() => {
                          versionUpdate(
                            item.SEQ,
                            item.VERSION,
                            item.DESCRIPTION
                          )
                        }}
                        style={{ backgroundColor: '#007ffb' }}
                      >
                        Save
                      </button>
                    </ButtonWrapper>
                  </VersionInputWrapper>
                </div>
              ) : (
                <VerWrapper key={index}>
                  <VerNum active={version && version.id === item.SEQ}>
                    <div
                      id="version"
                      onClick={() => {
                        setVersion({
                          id: item.SEQ,
                          version: item.VERSION,
                          status: item.STATUS,
                        })
                      }}
                    >
                      - {item.VERSION}
                    </div>
                  </VerNum>
                  {version && version.id === item.SEQ && (
                    <>
                      <VerDesc>
                        {item.DESCRIPTION.replaceAll('<br>', '\r\n')}
                      </VerDesc>
                      <ButtonWrapper>
                        <button
                          onClick={() => {
                            setPopup({ id: item.SEQ, type: 'delete' })
                          }}
                          style={{
                            backgroundColor: '#dc143c',
                            marginRight: '5px',
                          }}
                        >
                          Delete
                        </button>
                        <button
                          onClick={() => {
                            setPopup({ id: item.SEQ, type: 'copy' })
                          }}
                          style={{
                            backgroundColor: '#ff4500',
                            marginRight: '5px',
                          }}
                        >
                          Copy
                        </button>
                        <button
                          onClick={() => {
                            setEdit(item.SEQ)
                            setNewOpen(false)
                          }}
                          style={{
                            backgroundColor: '#007ffb',
                          }}
                        >
                          Edit
                        </button>
                      </ButtonWrapper>
                    </>
                  )}
                </VerWrapper>
              )
            )}
            {more !== 'prev' && prevV.length > 10 && (
              <More
                onClick={() => setMore('prev')}
                style={{
                  width: '100%',
                  textAlign: 'end',
                  padding: '5px 10px 0 0',
                }}
              >
                전체 보기
              </More>
            )}
          </Version>
        </>
      )}
    </Wrapper>
  )
}

export default VersionBanner

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 16px 0 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const Version = styled.div`
  width: 100%;
  background-color: #ecf0f5;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const VerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const VerNum = styled.div`
  padding: 2px 10px;
  display: flex;
  align-items: center;

  & #version {
    width: 90%;
    color: ${props => (props.active ? '#007bff' : '#000')};
    font-weight: ${props => (props.active ? 'bold' : 'normal')};
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;

    &:hover {
      color: #007bff;
      font-weight: bold;
      cursor: pointer;
    }
  }

  & #icon {
    width: 10%;
  }

  & #edit {
    color: #007bff;
    font-size: 14px;
    &:hover {
      color: #007bff;
      font-weight: bold;
      cursor: pointer;
    }
  }

  & #copy {
    color: #ff4500;
    font-size: 14px;
    margin-right: 10px;

    &:hover {
      color: #ff4500;
      font-weight: bold;
      cursor: pointer;
    }
  }
`

const VerDesc = styled.div`
  width: calc(100% - 10px);
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
  margin-left: 10px;
  margin-bottom: 10px;
  white-space: pre;
`

const VersionInputWrapper = styled.div`
  width: ${props => (props.edit ? 'calc(100% - 15px)' : '100%')};
  display: flex;
  flex-direction: column;
  margin-top: ${props => !props.edit && '10px'};
  margin-left: ${props => props.edit && '15px'};

  input[type='text'] {
    width: 100%;
  }

  textarea {
    width: 100%;
    min-height: 100px;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }
`

const ButtonWrapper = styled.div`
  width: calc(100% - 10px);
  margin-left: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;

  button {
    width: 100%;
    height: 30px;
    padding: 0 5px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 5px;

    &:hover {
      opacity: 0.8;
    }
  }
`

const More = styled.div`
  width: 100%;
  text-align: end;
  padding: 5px 10px 0 0;

  &:hover {
    cursor: pointer;
    color: #007ffb;
    font-weight: bold;
  }
`

import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import * as Pages from './Pages' //각 페이지들 리스트
import * as _Pages from '../src/_Pages' // 변환 완료된 페이지 리스트
import PrivateRoute from './Common/PrivateRoute'

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          {/* 변환 완료된 페이지 Route - 기존 페이지는 /v1/~~ 에 유지*/}
          <PrivateRoute
            exact
            path="/Banner/NewApp"
            component={Pages.NewAppBannerPage}
          />

          <PrivateRoute exact path="/Banner/NewB2B" component={Pages.NewB2BPage} />
          <PrivateRoute exact path="/Banner/NewHome" component={Pages.NewHomePage} />

          <PrivateRoute
            exact
            path="/Banner/Studio"
            component={_Pages.StudioBannerPage}
          />

          <PrivateRoute
            exact
            path="/Message/Qna/List/:pageIndex"
            component={_Pages.MessageQnaListPage}
          />

          <PrivateRoute
            exact
            path="/Message/Qna/Detail/:roomId"
            component={_Pages.MessageQnaDetailPage}
          />

          <PrivateRoute
            exact
            path="/Message/Qna/Send"
            component={_Pages.MessageQnaSendPage}
          />

          <PrivateRoute
            exact
            path="/Message/Notice"
            component={_Pages.MessageNoticePage}
          />

          <PrivateRoute
            exact
            path="/Message/Notice/Add"
            component={_Pages.MessageNoticeAddPage}
          />

          <PrivateRoute
            exact
            path="/Message/Notice/Edit"
            component={_Pages.MessageNoticeEditPage}
          />

          <PrivateRoute
            exact
            path="/Keyword/Untagged"
            component={_Pages.KeywordUntaggedListPage}
          />

          <PrivateRoute
            exact
            path="/Keyword/Untagged/Add/:stickerId"
            component={_Pages.KeywordUntaggedAddPage}
          />

          {/* 각 페이지들 나열, src/Components/LeftMenu.js 순서와 맞추기 */}
          <Route exact path="/" component={Pages.Login} />

          {/* 데이터(Data) Section*/}
          <PrivateRoute exact path="/Home" component={Pages.DashBoard} />
          <PrivateRoute
            exact
            path="/Dashboard/Overview"
            component={Pages.OverviewDashboard}
          />
          <PrivateRoute
            exact
            path="/Dashboard/Marketing"
            component={Pages.MarketingDashboard}
          />
          <PrivateRoute
            exact
            path="/Dashboard/Contents"
            component={Pages.ContentsDashboard}
          />

          <PrivateRoute
            exact
            path="/Dashboard/subscription"
            component={Pages.Subscription}
          />

          <PrivateRoute exact path="/Blog/post" component={_Pages.WritePostPage} />
          <PrivateRoute exact path="/Blog/done" component={_Pages.DonePostPage} />
          <PrivateRoute exact path="/Blog/view" component={_Pages.ViewPostsPage} />
          <PrivateRoute
            exact
            path="/Blog/view/:category/:pageNumber"
            component={_Pages.ViewPostsPage}
          />
          <PrivateRoute exact path="/Blog/edit" component={_Pages.EditPostsPage} />

          {/* 관리(Management) Section*/}
          <PrivateRoute
            exact
            path="/Banner/Mobile"
            component={Pages.MobileBannerListPage}
          />
          <PrivateRoute
            exact
            path="/Banner/Mobile/:c_id"
            component={Pages.MobileBannerInsertPage}
          />
          <PrivateRoute
            exact
            path="/v1/Banner/Studio"
            component={Pages.StudioBannerListPage}
          />
          <PrivateRoute
            exact
            path="/Banner/Studio/Insert"
            component={Pages.StudioBannerInsertPage}
          />
          <PrivateRoute exact path="/Assistant" component={Pages.Assistant} />
          <PrivateRoute exact path="/AssistantCreate" component={Pages.AssistantCreate} />
          
          <PrivateRoute exact path="/AssiDev" component={Pages.AssistantDev} />
          <PrivateRoute exact path="/AssiEdit" component={Pages.AssistantEdit} />
          <PrivateRoute exact path="/AssiCreateDev" component={Pages.AssistantCreateDev} />

          <PrivateRoute exact path="/Claude" component={Pages.ClaudePrompt} />

          <PrivateRoute exact path="/ContentsList" component={Pages.ContentsList} />
          <PrivateRoute
            exact
            path="/AI/ContentsList"
            component={Pages.AIContentsList}
          />
          <PrivateRoute
            exact
            path="/Premium/ContentsList"
            component={Pages.PremiumContentsList}
          />
          <PrivateRoute exact path="/BookmarkList" component={Pages.BookmarkList} />

          <PrivateRoute exact path="/Member/Artists" component={Pages.ArtistList} />

          <PrivateRoute
            exact
            path="/Member/ArtistGroupPage"
            component={Pages.ArtistGroupPage}
          />
          <PrivateRoute exact path="/Member/Users" component={Pages.UserList} />
          <PrivateRoute
            exact
            path="/Member/CorporateUsers"
            component={Pages.CorporateUsersPage}
          />
          <PrivateRoute
            exact
            path="/Member/ArtistSearch"
            component={Pages.ArtistSearch}
          />

          <PrivateRoute exact path="/AI/ChatContent" component={Pages.ChatContent} />
          <PrivateRoute exact path="/CategoryList" component={Pages.CategoryList} />
          <PrivateRoute exact path="/CategoryAdd" component={Pages.CategoryAdd} />

          <PrivateRoute
            exact
            path="/v1/ArtistQnaList"
            component={Pages.ArtistQnaList}
          />
          <PrivateRoute
            exact
            path="/v1/ArtistQnaDetail"
            component={Pages.ArtistQnaDetail}
          />

          <PrivateRoute exact path="/CommentList" component={Pages.CommentList} />

          <PrivateRoute exact path="/GuideList" component={Pages.GuideList} />

          <PrivateRoute
            exact
            path="/Taggers/Home"
            component={Pages.StickerTagHomePage}
          />
          <PrivateRoute
            exact
            path="/Taggers/:id/InsertTag"
            component={Pages.StickerTagInsertPage}
          />
          <PrivateRoute exact path="/TagDel" component={Pages.TagDel} />

          <PrivateRoute exact path="/LanguageJson" component={Pages.LanguageJsonPage} />
          <PrivateRoute exact path="/Subscription/Product" component={Pages.SubscriptionProductPage} />
          <PrivateRoute exact path="/Subscription/Artist" component={Pages.SubscriptionArtistPage} />

          <PrivateRoute exact path="/AI/Home" component={Pages.AIHomePage} />
          <PrivateRoute exact path="/AI/Prompt" component={Pages.PromptPage} />
          <PrivateRoute exact path="/AI/Create" component={Pages.CreatePage} />
          <PrivateRoute exact path="/AI/Contest" component={Pages.ContestPage} />
          <PrivateRoute exact path="/AI/Upload" component={Pages.AIUploadPage} />
          <PrivateRoute exact path="/AI/SDPrompt" component={Pages.SDPromptPage} />

          {/* 정산 Section*/}
          <PrivateRoute exact path="/Money/After" component={Pages.After} />
          <PrivateRoute exact path="/Money/Payment" component={Pages.Payment} />
          <PrivateRoute exact path="/Money/Tax" component={Pages.Tax} />
          <PrivateRoute
            exact
            path="/Money/ArtistLicense"
            component={Pages.ArtistLicensePage}
          />
          <PrivateRoute
            exact
            path="/Money/ArtistPaypal"
            component={Pages.ArtistPaypalPage}
          />
          <PrivateRoute exact path="/Money/TaxForm" component={Pages.TaxFormPage} />

          {/* B2B Section */}
          <PrivateRoute exact path="/AThemeList" component={Pages.AThemeList} />
          <PrivateRoute exact path="/AThemeInsert" component={Pages.AThemeInsert} />

          {/* 현재 사용하지 않음 Section, Hazel 요청으로 LeftMenu 하단에 보임*/}
          <PrivateRoute exact path="/WalletQnaList" component={Pages.WalletQnaList} />
          <PrivateRoute
            exact
            path="/WalletQnaDetail"
            component={Pages.WalletQnaDetail}
          />

          <PrivateRoute
            exact
            path="/StickerQnaList"
            component={Pages.StickerQnaList}
          />
          <PrivateRoute
            exact
            path="/StickerQnaDetail"
            component={Pages.StickerQnaDetail}
          />

          <PrivateRoute exact path="/Collection/Add" component={Pages.Collection} />
          <PrivateRoute
            exact
            path="/Collection/View"
            component={Pages.CollectionView}
          />

          <PrivateRoute exact path="/AppInfo" component={Pages.AppInfo} />
          <PrivateRoute exact path="/SdkInfo" component={Pages.SdkInfo} />
          <PrivateRoute exact path="/searchApi" component={Pages.searchApi} />
          <PrivateRoute exact path="/searchApiNew" component={Pages.searchApiNew} />
          <PrivateRoute
            exact
            path="/searchApiNewFixed"
            component={Pages.searchApiNewFixed}
          />
          <PrivateRoute
            exact
            path="/searchGraphionica"
            component={Pages.searchGraphionica}
          />
          <PrivateRoute
            exact
            path="/searchColorApi"
            component={Pages.searchColorApi}
          />
          <PrivateRoute exact path="/SearchHistory" component={Pages.SearchHistory} />
          <PrivateRoute exact path="/Curation" component={Pages.Curation} />
          <PrivateRoute exact path="/Preview" component={Pages.CurationPreview} />
          <PrivateRoute exact path="/Preview" component={_Pages.CurationPreviewPage} />
          <PrivateRoute
            exact
            path="/Curation/:cardId"
            component={Pages.CurationInsert}
          />

          <PrivateRoute exact path="/Recommend" component={Pages.Recommend} />
          <PrivateRoute exact path="/Billing" component={Pages.Billing} />

          <PrivateRoute exact path="/Download" component={Pages.Download} />
          <PrivateRoute exact path="/package" component={Pages.DownloadPackage} />
          <PrivateRoute exact path="/view" component={Pages.View} />
          <PrivateRoute exact path="/keyword" component={Pages.Keyword} />
          <PrivateRoute exact path="/RegCount" component={Pages.RegCount} />
          <PrivateRoute exact path="/SendCount" component={Pages.SendCount} />
          <PrivateRoute exact path="/SendSticker" component={Pages.SendSticker} />
          <PrivateRoute exact path="/Country" component={Pages.Country} />
          <PrivateRoute exact path="/AllSend" component={Pages.AllSend} />

          {/* If route exact path doesn't exist */}
          <Route exact path="*" component={Pages.DashBoard}>
            <Redirect to="/Home" />
          </Route>
        </Switch>
      </React.Fragment>
    )
  }
}

export default App
